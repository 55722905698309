.charte {
    position: absolute;
    width: 100%;
    height: 10vh;
    background-color: #4f5358;
    color: white;
    margin-left: -2%;
    margin-right: -2%;
    margin-top: 5vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    span {
        color: white;
        text-decoration-line: none;

        @media screen and (max-width: 900px){
            font-size: 12px;
        } 
        
        @media screen and (max-width: 400px){
            font-size: 10px;
        }  
        
        @media screen and (max-width: 300px){
            font-size: 8px;
        }
    }

    a {
        text-decoration: none;
    }
}