.cookies {
    .logo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .charte {
        position: absolute;
        bottom: 0;
    }

    main {
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}