.navigation {
    

  ul {
    a {
      text-decoration: none;
      padding: 10px;

      li {
        color: $color-1;
        transition: 0.2s;
        cursor: pointer;
        font-size: 25px;

        &:hover {
          color: $color-2;
        }
      }
    }

    .nav-active {
      position: relative;

      &::after {
        content: "";
        height: 4px;
        width: 0;
        background: $white;
        position: absolute;
        border-radius: 10px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-animation: anim 0.3s ease forwards;
        animation: anim 0.6s ease forwards;

        @-webkit-keyframes anim {
          to {
            width: 100%;
          }
        }
        @keyframes anim {
          to {
            width: 100%;
          }
        }
      }
    }
  }

  .buttonNav {
    position: absolute;
  }
  
  .navbar {
    position: fixed;
    width: min-content;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .menu-bars {
    width: 60px;
    height: 10px;
    border-radius: 5px;
    background: $color-1;
    display: block;
    margin: 3px;
  }
  
  .nav-menu {
    background-color: #060b26;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 2;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-menu-items {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }
  
  
  li {
    color: white;
    font-size: 20px;
    list-style-type: none;
  }

  .cross {
    position: absolute;
    top: 0px;
    right: 5px;
    font-size: 50px;
    cursor: pointer;
    transition: 2s ease;
     
    &:hover {
      transform: rotate(360deg);
    }
  }

  //icon

  .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    bottom: 20%;
    width: 100%;
    position: absolute;
  }

  .icon {
    color: azure;
    font-size: 25px;
    transition: 0.3s;

      &:hover {
        color: bisque;
      }
  }

}

  