.contact {

    header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 3vh;

        @media screen and (max-width: 600px){
            flex-direction: column;
        } 

        h1 {
            text-align: center;
        }

        .logo {
            width: auto;
        }
    }

    main {

        .icon-main {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .icon-container {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 50%;

            @media screen and (max-width: 6000px){
                width: 100%;
                margin-top: 5vh;
              } 
        }

        .icon {
            color: azure;
            font-size: 40px;
            transition: 0.3s;
            width: min-content;

            &:hover {
                color: bisque;
            }
        }
    }
}